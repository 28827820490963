import axios from 'axios'
import {API_URL} from "../../helpers/crud-helper/consts";

const COUNTRY_URL = `${API_URL}/countries`
const CONTACT_US_URL = `${API_URL}/contact`

const getCountries = (): Promise<any> => {
    return axios
        .get(`${COUNTRY_URL}`)
        .then((response) => response)
}

const contact = (value : any): Promise<any> => {
    return axios
        .post(CONTACT_US_URL, value)
        .then((response) => response)
}

const authExport = (value : any): Promise<any> => {
    return axios
        .post(`${API_URL}/export-users`, value)
        .then((response) => response)
}

export {getCountries , contact , authExport}
