import React, {FC, useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import clsx from "clsx";
import {authExport, contact, getCountries} from "../../core/home/requests";
import Select from "react-select";
import Terms from "../modals/terms";
import PrivacyPolicy from "../modals/privacy-policy";
import {Link} from "react-router-dom";

const exportSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required"),

});

const initialValues = {
    password: "",
};


const ExportForm: FC = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [error, setError] = useState<string | null>(null);


    const formik = useFormik({
        initialValues,
        validationSchema: exportSchema,
        onSubmit: async (values, { setSubmitting }) => {

            setErrorMessage(false)
            setSubmitting(true);
            try {
                setLoading(true);
                // setIsActive(true)
                // setTimeout(function () {
                //     setIsActive(false);
                //     // resetForm()
                // }, 1500);


                const {data} = await authExport(values);
               const customHeaders = [  'First Name' , 'Last Name' , 'Email' , 'Phone Number' ,'Client or AD', 'Nationality' , 'Reach Out'   , 'Registration Date'];
               const includeColumns = ['first_name','last_name','email','phone_number','client','country_id','source_of_reference','created_at']
                const filteredData = data.map((row:any) => {
                    const filteredRow: Record<string, any> = {};
                    includeColumns.forEach((col:any) => {
                        filteredRow[col] = row[col];
                    });
                    return filteredRow;
                });
               const header = customHeaders.join(',');
               const rows = filteredData.map((row:any) => Object.values(row).join(','));
               const csvData = `${header}\n${rows.join('\n')}`;
               const blob = new Blob([csvData], { type: 'text/csv' });
               const link = document.createElement('a');
               link.href = window.URL.createObjectURL(blob);
               link.download = 'exported_data.csv';
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
                setLoading(false);
            } catch (error) {
                setErrorMessage(true)
                setLoading(false);
                setError('Please enter the correct password');
            }

        },
    });





    return (

        <div className={'container'}>
            <div className="row justify-content-center">

                {isActive && (
                    <div className="col-md-10 ">
                        <div className="alert-box contact-alert mt-5">
                            <div className="alert alert-success alert-dismissible" role="alert">
                                The file will be downloaded shortly
                            </div>
                        </div>
                    </div>
                )
                }

                {errorMessage && (
                    <div className="col-md-10 ">
                        <div className="alert-box contact-alert mt-5">
                            <div className="alert alert-danger alert-dismissible" role="alert">
                                {error}
                            </div>
                        </div>
                    </div>
                )
                }
                <div className={'col-md-10 mt-5 '}>
                    <form className="contact-form " onSubmit={formik.handleSubmit} noValidate>
                        <div className="row justify-content-center">
                            <div className="form-group row">
                                <div className={'col-md-12'}>

                                    <input
                                        className={clsx(
                                            "form-control", {
                                                "is-invalid":
                                                    formik.touched.password && formik.errors.password,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.password && !formik.errors.password,
                                            })}
                                        type="password" placeholder={'Password'} required {...formik.getFieldProps("password")}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='invalid-feedback d-block'>
                                                                            <span
                                                                                role='alert'>{formik.errors.password}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className={'form-group row mt-4 mb-4'}>
                            <div className="col-md-4 ">
                                <button
                                    className="btn btn-main w-100"
                                    disabled={formik.isSubmitting }
                                    type="submit"
                                >
                                    {!loading && (
                                        <span className="indicator-label">
                               Export    </span>
                                    )}
                                    {loading && (
                                        <span
                                            className="indicator-progress"
                                            style={{display: "block"}}
                                        >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                    )}
                                </button>

                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>


    );
}

export default ExportForm;