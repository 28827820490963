import React, { Suspense } from 'react';
import ReactDOM, {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page404 from "./app/sections/404";
import Home from "./app/sections/home";
import Export from "./app/sections/export";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Page404 />,
        children: [
            {
                path: "/",
                element: <Home />,
            },

            {
                path: "/export",
                element: <Export />,
            },

        ],
    },
]);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <Suspense>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
