import React, {Fragment, FC, useRef, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Container, Navbar} from "react-bootstrap";


const Header: FC = () => {


    return (
        <Navbar className="bg-light">
            <Container className={'mb-3'}>
                <Navbar.Brand href="/" className={'mt-3 '}>
                            <div>
                                <img src={'/assets/images/logo.png'} />
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <div>
                        <img  src={'/assets/images/vector.png'} />
                    </div>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
};

export default Header;