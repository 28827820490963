import React, {FC, Fragment, useEffect, useState} from "react";
import ContactForm from "../components/forms/contact";



const Home: FC = () => {



    return (
       <>
       <ContactForm />
       </>
    );
};

export default Home;