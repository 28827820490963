import React, {FC, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";


interface PrivacyPolicyModalProps {
    isOpen: boolean;
    onClose: () => void;

}

const PrivacyPolicy: FC<PrivacyPolicyModalProps> = ({ isOpen, onClose}) => {

    return (
        <Modal size="lg" show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title> Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>
                   <p>
                       <strong>Vivium Living 1 Trading LLC.</strong>, a limited liability company incorporated under the Department of Economy & Tourism in Dubai, holding commercial license number 1064577, and with registered address at [▪], Dubai, United Arab Emirates (the “Company”) is a participant in the Dubai Downtown Design Event scheduled on 8-11 November 2023 (the “Event”) and will collect and process your personal data in the manner as set forth below.

                   </p>
                  <p>
                      Unless stated otherwise, any capitalised terms in this Privacy Notice are as defined in the Data Capturing Form or the Federal Decree-Law No. 45/2021 on the Protection of Personal Data (the “Law”).
                  </p>
                   <strong>1.	INTRODUCTION</strong>
                  <br/> The Company intends to collect the following categories of data (“Personal Data”) throughout the Event:
                   <br/> (a)	first name;
                   <br/> (b)	last name;
                   <br/> (c)	email address;
                   <br/> (d)	phone number; and
                   <br/> (e)	professional title.
                   <p> This Privacy Notice sets out how and why the Company collects and processes your Personal Data in connection with your visit to the Company’s booth during the Event. The Company shall at all times remain the Controller and shall process the Personal Data in accordance with the Law.
                   </p>
                  <p>
                      Should you have any questions about the use or processing of your Personal Data or your rights associated with the same,  you may contact the Company’s Data Protection Officer [name] via email at [email] or phone at [number].

                  </p>
                   <strong> 2.	RESPONSIBILITY OF YOUR PERSONAL DATA</strong>
                   <p> The Company is responsible for your Personal Data and will be the Controller of data collected during the Event.</p>
                   <p> Where you have provided explicit consent, the Company may share your Personal Data with the entities listed in Section 3.2 below.  In such instances, those entities will be both Controllers and Processors of the Personal Data and will process such data in accordance with the Law and any other rules or regulations applicable in the relevant jurisdiction.
                   </p>
                   <strong>3.	DATA PROCESSING</strong>
                  <strong>
                      3.1.	Purposes of Processing
                  </strong>
                   <p>The Company intends to Process your Personal Data for the following purposes (“Permitted Purposes”):</p>
                   (a)	Building a relationship and performance of a contract as agreed should you wish to engage our services;
                  <br/> (b)	Further to the provisions in Section 2.2 below, sending you marketing information in relation to our services, products, collections or anything in relation to the same;
                   <br/>  (c)	for quality control, business and statistical analysis, market research or for tracking fees and costs or for customer service, training and related purposes;
                   <br/>  (d)	to communicate with you by way of notice pursuant to applicable legislation or our constitution or circulating reports or other correspondence to you; or
                   <br/>  (e)	maintaining appropriate business records.
                  <br /> <br /> <strong>  3.2.	Transfer of Data for Marketing Purposes</strong>
                   With your explicit consent, the Company may communicate your data to the following entities for the Permitted Purposes to allow these entities to (i) send you information about collections, exhibitions, other institutional information, including newsletters in relation to their products and services, and (ii) request that you participate in various customer satisfaction surveys and market research to improve their products:
                   <br/> (a)	Cassina S.p.A. (“Cassina”) – Cassina’s privacy policy can be accessed on the website https://www.cassina.com/gb/en/legale/privacy-policy.html. Please refer to it for more information on how it processes personal data.
                   <br/>  (b)	Kettal SL (“Kettal”) – Kettal’s privacy policy can be accessed on the website https://www.kettal.com/living/en/privacy-policy.  Please refer to it for more information on how it processes personal data.
                 <p>
                     For purposes of this Section 3, both Cassina and Kettal shall be Controllers and Processors of your personal data.  Neither entity shall transfer your personal data to third parties without your explicit consent subject to the General Data Protection Regulation (EU) 2016/369 (the “GDPR”) and the Law.

                 </p>
                   <strong> 3.3.	Legal Basis of Processing</strong>
                   <p> Depending upon the specific Permitted Purposes we use your Personal Data, the Company may process your Personal Data on one or more of the following legal grounds:
                   </p>  (a)	Because processing is necessary for the performance of your instruction or other contract with you or your organisation and for us to be able to provide you with the requested service;
                   <br/>  (b)	To comply with our legal and/or regulatory obligations which become applicable to the Company from time to time;
                   <br/>   (c)	Because processing is necessary for purposes of our legitimate interest or those of any third party recipients that receive your Personal Data in managing our business including legal, personnel, administrative and management purposes and for the prevention and detection of crime, provided that such interests are not overridden by your interests or your fundamental rights and freedoms.
                   <br/>   (d)	Provided you have signed and returned the Data Capturing Form to our representative at the Event, we shall process your Personal Data based on your explicit consent.  You may choose to withdraw your consent at any time by contacting the Data Protection Officer or by clicking on the “unsubscribe” button in our emails and newsletters to you.
                   <br /> <br /> <strong> 3.4.	Retention</strong>
                <p>
                    Your Personal Data shall be retained by the Company from the date of collection during the Event until it is no longer reasonably required to retain your Personal Data for the Permitted Purposes, or until you withdraw your consent of processing the same, whichever is sooner (the “Processing Period”).

                </p>
                  <p>
                      At the end of the Processing Period, your data will be deleted or anonymized in order to prevent the Company, Cassina, Kettal, or third parties from connecting you to this data.

                  </p>
                  <strong>
                      3.5.	Obligations of the Company and its Processors
                  </strong>
                   <p>  As the Controller and a Processor, the Company shall take the appropriate technical and organizational measures to comply with Articles 7 and 8 of the Law. </p>
                   <p>  The Personal Data may be processed by persons employed and appointed by the Controller (each a “Processor”) who have been expressly authorised and have received adequate operating instructions in relation to the Permitted Purposes set out above. The Controller shall ensure that each Processor has provided sufficient guarantees to apply technical and organizational measures in a manner that ensures that the processing meets the requirements and controls stipulated in the Law, and that such Processor shall abide by the obligations as laid out in Article 8 of the Law. </p>
                   <p>  Each Processor shall erase the Personal Data after the expiry of the Processing Period (or hand it over to the Controller) and shall not take any action that would disclose the Personal Data or the results of processing, except in cases permitted by law.  Further, each Processor shall ensure the Personal Data Security measures outlined in Article 20 of the Law are in place when processing Personal Data. </p>
                   <p>  Additionally, the Company does not use profiling, where an electronic system uses your Personal Data to predict something about you, or other automated-decision making, where an electronic system uses your Personal Data to make a decision about you without any human intervention. </p>
                   <strong>   3.6.	Possible Third-Party Transfers</strong>
                   <p> Your information will not be sold to any third parties, but may be sent to entities that carry out and provide additional services related to the Permitted Purposes such as banking operators, electronic payment carriers, credit card issuers, internet providers, couriers and shipping agents, companies that manage events or carry out newsletter mailing or market research activities, companies that offer  IT infrastructures and IT support and consulting services, legal or accountancy firms, and auditors.</p>
                       <p> These entities may be located in countries which do not provide the same level of protection as the Law or the GDPR.  The Company will ensure that any such transfer are made subject to the appropriate safeguards required by the Law and GDPR (where applicable).  The Company will also require such entities to ensure a similar level of data protection as the Company in such instances.</p>
                 <strong>
                     3.7.	Minors
                 </strong>
                           <p> The services and products marketed by the Company are not intended for minors under the age of 18.  As such, the Company will not collect or process any Personal Data in relation to such minors.</p>
                  <strong>
                      4.	DATA SUBJECT RIGHTS
                  </strong>
                               <p>  As a Data Subject, in addition to the rights provided for you in the Law, you have the following rights in relation to how we collect and process your Personal Data:</p>
                 <br />  (a)	Right to obtain information about the type of Personal Data that is processed; controls and standards for the periods of strong your Personal Data; procedures in place for correcting, erasing or limiting the Personal Data processed; and other information as detailed in Article 13 of the Law;
                   <br />   (b)	Right to request the Company correct or erase your personal data;
                   <br />   (c)	Right to restrict the processing in the event a correction needs to be made in the Personal Data processed; you object to the processing of your Personal Data or believe there is a violation of the Purposes as stated under Section 3.1 above; and/or if there has been a violation of the provisions of the Law.
                   <br />   (d)	Right to stop the processing of your Personal Data by either sending a request to the Data Protection Officer or opting out of the Company’s marketing campaign by unsubscribing to any email or newsletter you receive.
                   <br />  <br /> <strong>  5.	UPDATES AND CHANGES  </strong>
                   <p> The Company may from time to time make changes to this Privacy Notice. Where these are likely to be material, we will communicate these  to you in advance. Otherwise, you may, at any time, access the latest version of this Privacy Notice by clicking on this [link].</p>


               </div>


            </Modal.Body>
        </Modal>
    );
};

export default PrivacyPolicy;
