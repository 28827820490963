import React, {FC, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";


interface TermsModalProps {
    isOpen: boolean;
    onClose: () => void;

}

const Terms: FC<TermsModalProps> = ({ isOpen, onClose}) => {



    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title> Terms & Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>

               </div>


            </Modal.Body>
        </Modal>
    );
};

export default Terms;
