import React from 'react';
import {Outlet} from "react-router-dom";
import "../src/assets/sass/style.scss";
import './App.css';
import Header from "./app/components/base/header";

function App() {

  return (
      <div className={''}>
          <Header/>
          <Outlet/>

      </div>
  );
}

export default App;
